import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, UntypedFormBuilder, Validators, } from "@angular/forms";
import { Observable, forkJoin } from "rxjs";
import { BreakpointObserver } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/material/stepper";
import { map } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Country } from "@angular-material-extensions/select-country";
import Langue from '../../../../../assets/langue.json';
import { NgxSpinnerService } from "ngx-spinner";
import { MatTableDataSource } from "@angular/material/table";
import { PiecesJService } from "../Services/pieces/pieces-j.service";
import { ArticleService } from "../Services/article/article.service";
import { CommerceService } from "../Services/commerce.service";
import { Router, ActivatedRoute } from "@angular/router";
import { OrganisationService } from 'src/Services/Organisation/organisation.service';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from "src/Services/User/user.service";


@Component({
  selector: "app-new",
  templateUrl: "./new.component.html",
  styleUrls: ["./new.component.css"],
})
export class NewComponent implements OnInit {
  Article_DATA: any[] = [];

  displayedColumns: string[] = [
    "no",
    "ngp",
    "des",
    "pfn",
    "codeqcs",
    "qcs",
    "pays",
  ];
  dataSource: MatTableDataSource<any>;

  Pieces_DATA: any[] = [];

  displayedColumnsPieces: string[] = ["name", "file"];
  dataSourcePieces: MatTableDataSource<any>;

  FormFde: FormGroup;
  FormExp: FormGroup;
  FormArticle: FormGroup;
  FormReg: FormGroup;
  FormPays: FormGroup;
  FormSign: FormGroup;
  FormPieces: FormGroup;
  b1 = "block";
  b2 = "none";

  id_bloc: any = 1;
  Bloc_Fde: boolean = true;
  Bloc_Pays: boolean = false;
  Bloc_EXP: boolean = false;
  Bloc_Article: boolean = false;
  Bloc_Devise: boolean = false;
  Bloc_Reg: boolean = false;
  Bloc_Sign: boolean = false;
  Bloc_Pie: boolean = false;
  Bloc_Res: boolean = false;


  langue: any;
  direction: any;
  languef: any;
  languea: any;
  langue_list: any[] = Langue;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe,
    private commerceservice: CommerceService,
    private modalService: NgbModal,
    private articleservice: ArticleService,
    private piecesjService: PiecesJService,
    breakpointObserver: BreakpointObserver,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private organisationService: OrganisationService,
    private userrService: UserService,

  ) {
    this.dataSource = new MatTableDataSource(this.Article_DATA);
    this.dataSourcePieces = new MatTableDataSource(this.Pieces_DATA);

    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].fop;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].fop;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].fop;
    }

    this.idFde = this.route.snapshot.params['id'];
    this.idFde_copy = this.route.snapshot.params['id_c'];
    if (this.idFde != undefined) {
      this.transactiontype = 'Update';
      this.getfde(this.idFde)
      //  this.idsc=this.idFde
    }

    if (this.idFde_copy != undefined) {

      this.transactiontype = 'Save';
      this.getfde(this.idFde_copy);

    }

    this.FormFde = this.formBuilder.group({
      numdemande: ["", Validators.required],
      ripdomiciliation: [""],
      nomsignd: [""],
      typedocument: [""],
      codeetat: [""],
      codeagencedepot: [""],
      code_agence_dom: [""],
      nom_agence_dom: [""],
      code_agence: [""],
      nom_agence: [""],
      codecontrat: [""],
      datecontrat: [""],
      numnature: [""],
      libellenature: [""],
      coderegime: [""],
      libelregime: [""],
      datedebutvalid: [""],
      datefinvalid: [""],
      modelivraison: [""],
      numeroDomiciliation: [""],
    });
    this.FormExp = this.formBuilder.group({

      nomexportateur: ["", Validators.required],
      add1: ["", Validators.required],
      rs: [""],
      codeExportateur: [""],
      ribClient: [""],
      nomimportateur: ["", Validators.required],
      add2: ["", Validators.required],
      rs1: [""],
      paysachat: [""],
      des1: [""],
      Desf: [""],
    });



    this.FormArticle = this.formBuilder.group({
      ngp: ["", Validators.required],
      libelle: ["", Validators.required],
      quantite: ["", Validators.required],
      pfn: ["", Validators.required],
      pays: ["", Validators.required],
      codeqcs: ["", Validators.required],
    });

    this.FormPays = this.formBuilder.group({
      paysachat: [""],
      des1: [""],
      Desf: [""],
    });

    this.FormReg = this.formBuilder.group({
      modereg: [""],
      delaireg: [""],
      devisreg: [""],
      devismonant: [""],
      courdevis: [""],
      pten: [""],
      fob: [""],
      ptend: [""],
      fobd: [""],
    });

    this.FormSign = this.formBuilder.group({
      nomsignd: [""],
    });

    this.FormPieces = this.formBuilder.group({
      name: ["", Validators.required],
      file: ["", Validators.required],
    });

    this.stepperOrientation = breakpointObserver
      .observe("(min-width: 800px)")
      .pipe(map(({ matches }) => (matches ? "horizontal" : "vertical")));
  }
  datedomiciliation = new FormControl();
  datecontrat = new FormControl();
  datedepot = new FormControl();

  date_current: any;
  ngOnInit(): void {
    this.date_current = new Date();
    this.GetIdUser();
    this.getBanque();
  }
  idFde: any;
  idFde_copy: any;
  selectetdocument: String | undefined;

  selectetdf: String | undefined;
  selectetd1: String | undefined;
  selectetp: String | undefined;
  selecteta: String | undefined;
  codecontrat: String | undefined;
  numnature: String | undefined;
  coderegime: String | undefined;
  numregime: String | undefined;
  modelivraison: String | undefined;
  delaireg: String | undefined;
  devisreg: String | undefined;
  devismont: String | undefined;

  codecontratlist: any[] = [
    "code contrat 1",
    "code contrat 2",
    "code contrat 3",
    "code contrat 4",
  ];
  // ngp: String | undefined;
  ngplist: any[] = [
    "code ngp 1",
    "code ngp 2",
    "code ngp 3",
    "code ngp 4",
  ];
  plist: any[] = ["P 1", "P 2", "P 3", "P 4"];
  d1list: any[] = [
    "Destination 1",
    "Destination 2",
    "Destination 3",
    "Destination 4",
  ];

  codeagencelistdomm: any[] = [
    { code: "a5214", name: "code agence domicillation 1" },
    { code: "a5214", name: "code agence domicillation 2" },
    { code: "a5214", name: "code agence domicillation 3" },
    { code: "a5214", name: "code agence domicillation 4" },
  ];
  selectetcodeagencedomm: String | undefined;
  modelivraisonlist: any[] = [
    "EXWEXW",
    "FCA",
    "FOB",
    "FAS",
    "CFR",
    "CIF",
    "CPT",
    "CIP",
    "DAF",
    "DES",
    "DEQ",
    "DDU",
    "DDP",
  ];
  devislistmontant: any[] = ["devis  1", "devis 2", "devis 3"];
  naturelist: any[] = [
    "ExportLicense_21",
    "ImportLicense_31",
    "ImportCertificate_33",
    "FinalExportInvoice_22",
    "TemporaryAdmission_39",
  ];
  dflist: any[] = ["df 1", "df 2"];
  alist: any[] = ["achat1", "achat2", "achat3"];
  codelistregime: any[] = ["code regime1", "code regime1", "code regime1"];
  doclist: any[] = ["Doc 1", "Doc 2", "Doc 3", "Doc 4"];

  modereglist: any[] = [
    "PaymentNoLaterThan1Year_01",
    "PaymentNoLaterThan2Years_02",
    "PaymentNoLaterThan3Years_03",
    "PaymentNoLaterThan4Years_04",
    "PaymentNoLaterThan5Years_05",
    "PaymentNoLaterThan6Years_06",
    "aymentNoLaterThan7Years_07",
    "PaymentNoLaterThan8Years_08",
    "PaymentNoLaterThan9Years_09",
    "PaymentNoLaterThan10Years_10",
    "PaymentcashOrNoLaterThan30Days_11",
    "PaymentInPeriodExceeding10Years_20",
    "PaymentNoLaterThan2Months_22",
    "PaymentNoLaterThan3Months_33",
    "PaymentNoLaterThan6Months_66",
    "Others_88",
    "PaymentNoLaterThan9Months_99",
  ];

  UserId: any;
  id_Org_en: any;
  GetIdUser() {
    this.userrService.Getuserbyusername().subscribe((res: any) => {
      this.UserId = res.id;
      this.Getorganisation_send();
    },

      error => {
        console.log(error);
      }
    );
  }

  Getorganisation_send() {
    this.organisationService.Get_org_byID(this.UserId).subscribe((res: any) => {
      this.id_Org_en = res.id;

      this.FormExp.patchValue({
        nomexportateur: res.name,
        //rs: res.
        codeExportateur: res.registrationNumber,
        ribCient: res.phone,
        add1: res.address
      });
    });
  }

  delaireglist: any[] = ["delai 1", "delai 2"];
  devisreglist: any[] = ["USD"];
  changedocument(event: any) { }

  selectetcode: String | undefined;
  moderegg: String | undefined;
  codelist: any[] = ["Doc 1", "Doc 2", "Doc 3", "Doc 4"];

  selectetcodeagence: String | undefined;
  codeagencelist: any[] = ["agence 1", "agenc 2", "agence 3", "agenc 4"];
  changecode(event: any) { }

  date_domiciliation: any;
  onchangedatedomiciliation(event: any) {
    this.date_domiciliation = this.datePipe.transform(
      this.datedomiciliation.value,
      "yyyy-MM-dd"
    );
  }

  nom_agence_dom: any = "";
  onchangedomiciationagece(event: any) {

    this.nom_agence_dom = event.nameOrg;

    if (this.nom_agence_dom) {
      this.GetAgencesBanque(this.nom_agence_dom);
      this.FormFde.controls['nom_agence'].reset();
      this.FormFde.controls['code_agence_dom'].reset();
    }

  }

  code_agence_dom: any;
  nom_agence: any = "";
  onchangenomagece(event: any) {
    this.code_agence_dom = event.code_agence;
    this.nom_agence = event.agence;
    this.FormFde.controls['code_agence_dom'].setValue(this.code_agence_dom);
  }

  GetAgencesBanque(nom_agence_dom: any) {
    this.commerceservice.GetAgencesBanque(nom_agence_dom).subscribe((res: any) => {
      this.AgenceList = res;
    });
  }


  onchangedatedepot(event: any) { }


  reinitialise() {
    this.Bloc_Fde = false;
    this.Bloc_Pays = false;
    this.Bloc_EXP = false;
    this.Bloc_Article = false;
    this.Bloc_Devise = false;
    this.Bloc_Reg = false;
    this.Bloc_Sign = false;
    this.Bloc_Pie = false;
    this.Bloc_Res = false;
  }

  bloc1: boolean = true;
  bloc2: boolean = false;
  onchangeb(event: any) {
    if (event.value == 1) {
      this.bloc1 = true;
      this.bloc2 = false;

      this.b1 = "block";
      this.b2 = "none";
    } else {
      this.bloc2 = true;
      this.bloc1 = false;
      this.b2 = "block";
      this.b1 = "none";
    }
  }

  AgenceList: any[] = []
  getAgence() {
    this.commerceservice.GetAllAgences().subscribe((res: any) => {
      this.AgenceList = res;
    });
  }

  RibBankListExist: any[] = [];
  BankList: any[] = []
  idBank: any;
  getBanque() {
    this.commerceservice.getProfilDestinataire().subscribe((profil: any) => {
      // Use forkJoin to parallelize API calls
      forkJoin([
        this.organisationService.GetbyProfil(profil),
        this.commerceservice.GetAllBanques()
      ]).subscribe(([bankListRes, banquesRes]: [any, any]) => {
        this.BankList = bankListRes;

        // Use map and filter in the same step
        const ribClient = this.FormExp.controls["ribClient"].value.trim().toLowerCase();
        const ribPrefix = ribClient.substring(0, 2);

        this.BankList = banquesRes
          .filter((Rib: any) => Rib.rib.trim().toLowerCase().startsWith(ribPrefix))
          .map((Rib: any) => {
            return this.BankList.find((Bank: any) => Bank.nameOrg === Rib.nom_bank);
          })
          .filter((bank: any) => !!bank); // Filter out undefined values

        // Extract bank IDs
        this.idBank = this.BankList.map((Bank: any) => Bank.id);
      });
    });
  }

  getfde(id: any) {
    this.commerceservice.GetById(id).subscribe((res: any) => {
      this.document_fde = res;
      this.remplirForm();
      // this.Get_Article(this.document_fde.articleFdes)


    });
  }
  document_fde: any;
  remplirForm() {
    this.FormExp.controls['add1'].setValue(
      this.document_fde.adresseExportateur
    );
    this.FormExp.controls['add2'].setValue(
      this.document_fde.adresseImportateur
    );
    this.devismont = this.document_fde.codeDeviseMontant,
      this.modelivraison = this.document_fde.codeModeLivraison,
      this.numnature = this.document_fde.codeNatureAutorisation,
      //this.code_agence_dom = this.document_fde.codeOrganismeDomicilation,
      /*this.FormFde.controls['nom_agence_dom'].setValue(
        this.document_fde.designationOrganismeDomiciliation
      );*/
      this.FormFde.controls["nom_agence"].setValue(this.document_fde.nomSignataireBanque);

    this.FormFde.controls['code_agence_dom'].setValue(
      this.document_fde.codeOrganismeDomicilation
    );

    this.Valuea = {
      name: this.document_fde.nompaysachat,
      alpha2Code: this.document_fde.codePaysAchat,
      alpha3Code: "",
      numericCode: "",
      callingCode: "",
    };
    this.Valuep = {
      name: this.document_fde.designationPaysProvenance,
      alpha2Code: this.document_fde.codePaysProvenance,
      alpha3Code: "",
      numericCode: "",
      callingCode: "",
    };
    this.Valued1 = {
      name: this.document_fde.designationPaysPremiereDestination,
      alpha2Code: this.document_fde.codePaysPremiereDestination,
      alpha3Code: "",
      numericCode: "",
      callingCode: "",
    };
    this.Valuedf = {
      name: this.document_fde.designationPaysDestinationDefinitive,
      alpha2Code: this.document_fde.codePaysDestinationDefinitive,
      alpha3Code: "",
      numericCode: "",
      callingCode: "",
    };
    this.datecontract.setValue(this.document_fde.dateContratCommercial),
      this.datesignd.setValue(this.document_fde.dateDeclarant),
      this.datedomiciliation.setValue(this.document_fde.dateDomiciliation),
      this.codecontrat = this.document_fde.codeobjet,
      this.delaireg = this.document_fde.delaiReglement,
      this.nom_agence_dom = this.document_fde.designationOrganismeDomiciliation,
      this.nompaysachat = this.document_fde.designationPaysAchat,
      this.nompaysDF = this.document_fde.designationPaysDestinationDefinitive,
      this.moderegg = this.document_fde.designationReglement,
      this.devisreg = this.document_fde.deviseReglement,

      this.FormReg.controls["fob"].setValue(this.document_fde.fcbDevise),
      this.FormReg.controls["fobd"].setValue(this.document_fde.fcbDinars)
    this.moderegg = this.document_fde.modeReglement,
      this.FormFde.controls["nomsignd"].setValue(this.document_fde.nomDeclarant)

    this.FormFde.controls["numeroDomiciliation"].setValue(this.document_fde.numeroDomiciliation),

      //libellenature

      this.FormFde.controls["libellenature"].setValue(this.document_fde.designationNatureAutorisation)

    this.FormExp.controls["nomexportateur"].setValue(this.document_fde.nomPrenomExportateur)
    this.FormExp.controls["rs"].setValue(this.document_fde.raisonSocialExportateur)
    this.FormExp.controls["codeExportateur"].setValue(this.document_fde.codeExportateur)
    this.FormExp.controls["ribClient"].setValue(this.document_fde.rib)

    this.FormExp.controls["nomimportateur"].setValue(this.document_fde.nomPrenomImportateur)
    this.FormExp.controls["rs1"].setValue(this.document_fde.raisonSocialImportateur)

    //
    this.FormReg.controls["pten"].setValue(this.document_fde.ptfnDevise)
    this.FormReg.controls["ptend"].setValue(this.document_fde.ptfnDinars)
    this.Article_DATA = this.document_fde.articleFdes

    setTimeout(() => {

      this.dataSource = new MatTableDataSource(this.Article_DATA);
    }, 300);
  }
  /* protected createFromFde(): any {
     return {
       articleFdes: [
         {
           codePaysOrigine: "string",
           designation: "string",
           id: 0,
           nomPaysOrigine: "string",
           nomenclature: "string",
           numeroArticle: "string",
           prix: 0,
           quantite: 0,
           tceId: 0,
           uniteMesure: "string",
         },
       ],
       tce: {
         adresseExportateur: this.FormExp.controls["add1"].value,
         adresseImportateur: this.FormExp.controls["add1"].value,
         codeDeviseMontant: "",
         codeExportateur: "",
         codeImportateur: "",
         codeModeLivraison: this.modelivraison,
         codeNatureAutorisation: "",
         codeOrganismeDomicilation: this.code_agence_dom,
         codePaysAchat: this.selecteta,
         codePaysDestinationDefinitive: this.selectetdf,
         codePaysPremiereDestination: this.selectetd1,
         codePaysProvenance: this.selectetp,
         dateContratCommercial:this.datecontract,
         dateDeclarant: this.datedomiciliation,
         dateDomiciliation: this.date_domiciliation,
         dateSignatureBanque: "",
         dateSignatureDocumentBc: "",
         delaiReglement: "",
         designationModeLivraison: this.modelivraison,
         designationNatureAutorisation: "",
         designationOrganismeDomiciliation: this.nom_agence_dom,
         designationPaysAchat: "",
         designationPaysDestinationDefinitive: "",
         designationPaysPremiereDestination: "",
         designationPaysProvenance: "",
         designationReglement: "",
         deviseReglement: "",
         fcbDevise: 0,
         fcbDinars: 0,
         modeReglement: "",
         nomDeclarant: "",
         nomOrganismeBc: "",
         nomPrenomExportateur: this.FormExp.controls["nomexportateur"].value,
         nomPrenomImportateur: this.FormExp.controls["nomimportateur"].value,
         nomSignataireBanque: "",
         nomSignataireBc: "",
         numeroContratCommercial: "",
         numeroDomiciliation: "",
         processInstanceId: "",
         ptfnDevise: 0,
         ptfnDinars: 0,
         raisonSocialExportateur: "",
         raisonSocialImportateur: "",
         reponseBanque: "",
 
         signatureBanque: "",
         signatureBc: "",
         typeDocumentContrat: "",
       },
     };
   }*/

  etat: any;
  phase: any;
  Go_list() {
    this.modalService.dismissAll();
    this.router.navigateByUrl('Customs/liste');
    //localStorage.setItem()
  }
  transactiontype = 'Save';

  Save(content: any) {
    this.etat = "Save";
    this.phase = "";

    if (this.transactiontype == 'Save') {
      this.articleservice;
      this.Register_Article();
      setTimeout(() => {
        this.spinner.show();
        this.commerceservice.post(this.createFromFdetotal(), this.idBank).subscribe(
          (res: any) => {
            this.spinner.hide();

            setTimeout(() => {
              this.Register_pices();
            }, 200);
            this.open(content);
            setTimeout(() => {
              this.dimmis();
              this.Go_list()
            }, 700);
          },
          (error) => {
            this.spinner.hide(); //Error callback
            //throw error;   //You can also throw the error to a global error handler
          }
        );
      }, 500);
    }
    else {
      this.Register_Article();

      this.commerceservice
        .post(this.createFromFdetotalUpdate(), this.idBank)
        .subscribe((res: any) => {
          setTimeout(() => {
            this.open(content);
            this.Register_pices();

            this.transactiontype = 'Update';

          }, 1000);
          this.Go_list()
        });

    }
  }



  Submit(content: any) {
    this.etat = "For Confirmation";
    this.articleservice;

    this.Register_Article();

    setTimeout(() => {
      this.spinner.show();
      this.commerceservice.submit(this.createFromFdetotalUpdate(), this.idBank).subscribe(
        (res: any) => {
          this.spinner.hide();
          this.Id_tce = res.id;

          setTimeout(() => {
            this.Register_pices();
          }, 200);
          this.open(content);
          setTimeout(() => {
            this.dimmis();
            this.Go_list()
          }, 700);
        },
        (error) => {
          this.spinner.hide(); //Error callback
        }
      );
    }, 500);
  }

  Id_tce: any;

  protected createFromArticle(): any {
    return {
      codePaysOrigine: "",
      designation: "",
      nomPaysOrigine: this.FormArticle.controls["pays"].value,
      nomenclature: this.FormArticle.controls["ngp"].value,
      prix: this.FormArticle.controls["pfn"].value,
      quantite: this.FormArticle.controls["quantite"].value,
      uniteMesure: this.FormArticle.controls["codeqcs"].value,
    };
  }

  protected createFromPiece(nom: any, file: any): any {
    return {
      name: nom,
      file: file,
      tceId: this.Id_tce,
    };
  }
  protected createFromFdetotal(): any {
    return {
      adresseExportateur: this.FormExp.controls["add1"].value,
      adresseImportateur: this.FormExp.controls["add2"].value,
      codeDeviseMontant: this.devismont,
      codeExportateur: this.FormExp.controls["codeExportateur"].value,
      rib: this.FormExp.controls["ribClient"].value,
      codeImportateur: "",
      codeModeLivraison: this.modelivraison,
      codeNatureAutorisation: this.numnature,
      codeOrganismeDomicilation: this.code_agence_dom,
      codePaysAchat: this.codepaysachat,
      codePaysDestinationDefinitive: this.codepaysDF,
      codePaysPremiereDestination: this.codepaysD1,
      codePaysProvenance: this.codepaysP,
      dateContratCommercial: this.date_contrat,
      dateDeclarant: this.date_signd,
      //dateDomiciliation: this.date_domiciliation,
      codeobjet: this.codecontrat,
      delaiReglement: this.delaireg,
      designationModeLivraison: this.modelivraison,
      designationNatureAutorisation: this.FormFde.controls["libellenature"].value,
      designationOrganismeDomiciliation: this.nom_agence_dom,
      designationPaysAchat: this.nompaysachat,
      designationPaysDestinationDefinitive: this.nompaysDF,
      designationPaysPremiereDestination: this.nompaysD1,
      designationPaysProvenance: this.nompaysP,
      designationReglement: this.moderegg,
      deviseReglement: this.devisreg,
      fcbDevise: this.FormReg.controls["fob"].value,
      fcbDinars: this.FormReg.controls["fobd"].value,
      modeReglement: this.moderegg,
      nomDeclarant: this.FormFde.controls["nomsignd"].value,
      nomOrganismeBc: "",
      nomPrenomExportateur: this.FormExp.controls["nomexportateur"].value,
      nomPrenomImportateur: this.FormExp.controls["nomimportateur"].value,
      nomSignataireBanque: this.nom_agence,
      nomSignataireBc: "",
      numeroContratCommercial: this.codecontrat,
      //numeroDomiciliation: this.FormExp.controls["numeroDomiciliation"].value,
      processInstanceId: "",
      ptfnDevise: this.FormReg.controls["pten"].value,
      ptfnDinars: this.FormReg.controls["ptend"].value,
      raisonSocialExportateur: this.FormExp.controls["rs"].value,
      raisonSocialImportateur: this.FormExp.controls["rs"].value,
      reponseBanque: "",
      signatureBanque: "",
      signatureBc: "",
      typeDocumentContrat: "",
      etat: this.etat,
      phase: this.phase,
      articleFdes: this.Article_list,
    };
  }
  protected createFromFdetotalUpdate(): any {
    return {
      id: this.idFde,
      adresseExportateur: this.FormExp.controls["add1"].value,
      adresseImportateur: this.FormExp.controls["add1"].value,
      codeDeviseMontant: this.devismont,
      codeExportateur: this.FormExp.controls["codeExportateur"].value,
      codeImportateur: "",
      codeModeLivraison: this.modelivraison,
      codeNatureAutorisation: this.numnature,
      codeOrganismeDomicilation: this.code_agence_dom,
      codePaysAchat: this.codepaysachat,
      codePaysDestinationDefinitive: this.codepaysDF,
      codePaysPremiereDestination: this.codepaysD1,
      codePaysProvenance: this.codepaysP,
      dateContratCommercial: this.date_contrat,
      dateDeclarant: this.date_signd,
      dateDomiciliation: this.date_domiciliation,
      codeobjet: this.codecontrat,

      delaiReglement: this.delaireg,
      designationModeLivraison: this.modelivraison,
      designationNatureAutorisation: this.FormFde.controls["libellenature"].value,
      designationOrganismeDomiciliation: this.nom_agence_dom,
      designationPaysAchat: this.nompaysachat,
      designationPaysDestinationDefinitive: this.nompaysDF,
      designationPaysPremiereDestination: this.nompaysD1,
      designationPaysProvenance: this.nompaysP,
      designationReglement: this.moderegg,
      deviseReglement: this.devisreg,
      fcbDevise: this.FormReg.controls["fob"].value,
      fcbDinars: this.FormReg.controls["fobd"].value,
      modeReglement: this.moderegg,
      nomDeclarant: this.FormSign.controls["nomsignd"].value,
      nomOrganismeBc: "",
      nomPrenomExportateur: this.FormExp.controls["nomexportateur"].value,
      nomPrenomImportateur: this.FormExp.controls["nomimportateur"].value,
      nomSignataireBanque: this.nom_agence,
      nomSignataireBc: "",
      numeroContratCommercial: this.codecontrat,
      //numeroDomiciliation: this.FormExp.controls["numeroDomiciliation"].value,
      processInstanceId: "",
      ptfnDevise: this.FormReg.controls["pten"].value,
      ptfnDinars: this.FormReg.controls["ptend"].value,
      raisonSocialExportateur: this.FormExp.controls["rs"].value,
      raisonSocialImportateur: this.FormExp.controls["rs"].value,
      reponseBanque: "",
      signatureBanque: "",
      signatureBc: "",
      typeDocumentContrat: "",
      etat: this.etat,
      phase: this.phase,
      articleFdes: this.Article_list,
    };
  }
  date_contrat: any;
  date_debut_valid: any;
  date_fin_valid: any;
  datecontract = new FormControl();
  datedebutvalid = new FormControl();
  datefinvalid = new FormControl();
  onchangedateconrat(event: any) {
    this.date_contrat = this.datePipe.transform(
      this.datecontract.value,
      "yyyy-MM-dd"
    );
  }

  onchangedatedebutvalid(event: any) {
    this.date_debut_valid = this.datePipe.transform(
      this.datedebutvalid.value,
      "yyyy-MM-dd"
    );
  }

  onchangedatefinvalid(event: any) {
    this.date_fin_valid = this.datePipe.transform(
      this.datefinvalid.value,
      "yyyy-MM-dd"
    );
  }

  stepperOrientation: Observable<StepperOrientation>;

  datesign = new FormControl();
  date_sign: any;
  onchangedatesign(event: any) {
    this.date_sign = this.datePipe.transform(this.datesign.value, "yyyy-MM-dd");
  }

  datesignd = new FormControl();
  date_signd: any;
  onchangedatesignd(event: any) {
    this.date_signd = this.datePipe.transform(
      this.datesignd.value,
      "yyyy-MM-dd"
    );
  }
  file = "";
  onChangefile(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.file = file;
    }
  }
  open(content: any) {
    this.modalService.open(content);
  }
  file_add() {
    this.dimmis();

    this.Pieces_DATA.push({
      name: this.FormPieces.controls["name"].value,
      file: this.file,
    });

    setTimeout(() => {
      this.dataSourcePieces = new MatTableDataSource(this.Pieces_DATA);
    }, 300);

    this.FormPieces.reset();
  }

  Pieceslist: any[] = [];
  dimmis() {
    this.modalService.dismissAll();
  }

  codepaysachat: any;

  nompaysachat: any;
  onCountrySelectedA(event: any) {

    this.codepaysachat = event.alpha2Code;
    this.nompaysachat = event.name;
  }

  codepaysP: any;
  nompaysP: any;
  onCountrySelectedP(event: any) {
    this.codepaysP = event.alpha2Code;
    this.nompaysP = event.name;
  }

  codepaysD1: any;
  nompaysD1: any;
  onCountrySelectedD1(event: any) {
    this.codepaysD1 = event.alpha2Code;
    this.nompaysD1 = event.name;
  }

  codepaysDF: any;
  nompaysDF: any;
  onCountrySelectedDF(event: any) {
    this.codepaysDF = event.alpha2Code;
    this.nompaysDF = event.name;
  }

  Valuep: Country = {
    name: "",
    alpha2Code: "",
    alpha3Code: "",
    numericCode: "",
    callingCode: "",
  };
  Valuea: Country = {
    name: "",
    alpha2Code: "",
    alpha3Code: "",
    numericCode: "",
    callingCode: "",
  };
  Valued1: Country = {
    name: "",
    alpha2Code: "",
    alpha3Code: "",
    numericCode: "",
    callingCode: "",
  };
  Valuedf: Country = {
    name: "",
    alpha2Code: "",
    alpha3Code: "",
    numericCode: "",
    callingCode: "",
  };



  Article_list: any[] = [];

  Register_Article() {
    for (let article of this.Article_DATA) {
      this.Article_list.push({
        codePaysOrigine: article.pays,
        designation: article.designation,
        nomPaysOrigine: article.nomPaysOrigine,
        nomenclature: article.nomenclature,
        numeroArticle: article.numeroArticle,
        prix: article.prix,
        quantite: article.quantite,
        tceId: this.Id_tce,
        uniteMesure: article.uniteMesure,
      });
    }
  }

  i = 0;
  article_add() {
    this.i = this.i + 1;

    this.Article_DATA.push({
      numeroArticle: this.i,
      uniteMesure: this.FormArticle.controls["codeqcs"].value,
      designation: this.FormArticle.controls["libelle"].value,
      quantite: this.FormArticle.controls["quantite"].value,
      prix: this.FormArticle.controls["pfn"].value,
      nomPaysOrigine: this.FormArticle.controls["pays"].value,
      nomenclature: this.FormArticle.controls["ngp"].value,
    });

    this.dimmis();

    setTimeout(() => {
      this.FormArticle.reset();

      this.dataSource = new MatTableDataSource(this.Article_DATA);
    }, 300);
  }
  Pieces_list: any[] = [];
  Register_pices() {
    for (let piece of this.Pieces_DATA) {
      let formData = new FormData();

      formData.append("file", piece.file);

      this.piecesjService
        .post(this.Id_tce, formData)
        .subscribe((res: any) => { });
    }
  }

  contour = 1;
  activestep1: any = 'active';
  activestep2: any;
  activestep3: any;
  activestep4: any;
  activestep5: any;
  renisialisation() {
    this.activestep1 = '';
    this.activestep2 = '';
    this.activestep3 = '';
    this.activestep4 = '';
    this.activestep5 = '';
  }

  rib: any;
  changebloc(numbloc: any) {
    console.log("ID de la banque récupéré: " + this.BankList);
    console.log("ID de la banque récupéré this.getBanque() : " + this.getBanque());
    console.log("ID de la banque récupéré: this.idBank " + this.idBank);
    this.getBanque();
    this.validForm(this.contour);
    if (this.status == true) {
      this.switchbloc(numbloc);
    }


  }

  formSubmitted: boolean = false
  reponseRequired: any
  reponseRequired1: any

  status: boolean;
  validForm(numbloc: any) {
    this.formSubmitted = false;
    switch (numbloc) {
      case 1:
        this.formSubmitted = true;
        this.reponseRequired = 'Champ Obligatoire';
        if (this.FormExp.valid) {
          this.status = true;
          return;
        } else {
          this.status = false;
        }
        break;

      case 2:
        this.formSubmitted = true;
        this.reponseRequired1 = 'Champ Obligatoire';
        if (this.FormExp.valid) {
          this.status = true;
          return;
        } else {
          this.status = false;
        }
        break;
    }
  }

  switchbloc(numbloc: any) {
    this.contour = numbloc;
    this.renisialisation();

    switch (numbloc) {
      case 1:
        this.activestep1 = 'active';
        break;
      case 2:
        this.activestep2 = 'active';
        break;
      case 3:
        this.activestep3 = 'active';
        break;
      case 4:
        this.activestep4 = 'active';
        break;
      case 5:
        this.activestep5 = 'active';
        break;
    }
  }

  onFileChange(event: any) {
    this.spinner.show();
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];


      if (ws['H2'] != undefined) {
        this.FormExp.controls['nomexportateur'].setValue(ws['H2']['v']);
      }

      if (ws['H3'] != undefined) {
        this.FormExp.controls['add1'].setValue(ws['H3']['v']);
      }


      if (ws['H4'] != undefined) {
        this.FormExp.controls['rs'].setValue(ws['H4']['v']);
      }


      if (ws['D9'] != undefined) {
        this.FormExp.controls['nomimportateur'].setValue(ws['D9']['v']);
      }

      if (ws['D10'] != undefined) {
        this.FormExp.controls['rs1'].setValue(ws['D10']['v']);
      }


      if (ws['D11'] != undefined) {
        this.FormExp.controls['add2'].setValue(ws['D11']['v']);
      }


      if (ws['D14']['v'] != undefined) {
        this.Valuep = {
          name: "",
          alpha2Code: ws['D14']['v'],
          alpha3Code: '',
          numericCode: '',
          callingCode: '',
        };

        this.codepaysP = ws['D14']['v'];
        this.nompaysP = ws['D15']['v'];



      }

      if (ws['D17']['v'] != undefined) {
        this.Valuep = {
          name: "",
          alpha2Code: ws['D16']['v'],
          alpha3Code: '',
          numericCode: '',
          callingCode: '',
        };

        this.codepaysachat = ws['D16']['v'];
        this.nompaysachat = ws['D17']['v'];


      }

      if (ws['F14']['v'] != undefined) {
        this.Valued1 = {
          name: '',
          alpha2Code: ws['F14']['v'],
          alpha3Code: '',
          numericCode: '',
          callingCode: '',
        };


        this.codepaysD1 = ws['F14']['v'];
        this.nompaysD1 = ws['F15']['v'];
      }

      if (ws['F16']['v'] != undefined) {
        this.Valuedf = {
          name: '',
          alpha2Code: ws['F16']['v'],
          alpha3Code: '',
          numericCode: '',
          callingCode: '',
        };

        this.codepaysDF = ws['F16']['v'];
        this.nompaysDF = ws['F17']['v'];



      }




      if (ws['J14'] != undefined) {
        this.datedomiciliation.setValue(new Date(ws['J14']['v']));

      }

      if (ws['J15'] != undefined) {

        this.code_agence_dom = ws['J15']['v'];
        this.nom_agence_dom = ws['J15']['v'];
        this.nom_agence = ws['J15']['v'];

      }



      if (ws['M2'] != undefined) {
        this.FormFde.controls['nomsignd'].setValue(ws['M2']['v'])
      }


      if (ws['M3'] != undefined) {
        this.datesignd.setValue(new Date(ws['M3']['v']));

      }

      if (ws['P13'] != undefined) {
        this.codecontrat = ws['P13']['v'];
      }



      if (ws['P14'] != undefined) {
        this.datecontract.setValue(new Date(ws['P14']['v']));

      }


      if (ws['S15'] != undefined) {
        this.numnature = ws['S15']['v'];
      }

      if (ws['S14'] != undefined) {
        this.FormFde.controls['libellenature'].setValue(ws['S14']['v'])

      }



      if (ws['M9'] != undefined) {
        this.moderegg = ws['M9']['v'];
      }

      if (ws['M11'] != undefined) {
        this.delaireg = ws['M11']['v'];
      }

      if (ws['M13'] != undefined) {
        this.devisreg = ws['M13']['v'];
      }


      if (ws['P1'] != undefined) {
        this.devismont = ws['P1']['v'];
      }


      if (ws['P2'] != undefined) {
        this.FormReg.controls['pten'].setValue(ws['P2']['v'])

      }

      if (ws['p8'] != undefined) {
        this.FormReg.controls['fob'].setValue(ws['p8']['v'])

      }

      if (ws['P2'] != undefined) {
        this.FormReg.controls['ptend'].setValue(ws['P2']['v'])

      }

      if (ws['p8'] != undefined) {
        this.FormReg.controls['fobd'].setValue(ws['p8']['v'])

      }
      let z = 22;

      for (var i = 21; i < z; i++) {
        if (ws['B' + i] != undefined) {
          this.Article_DATA.push({
            no: ws['B' + i]['v'],
            pc: ws['C' + i]['v'],
            sds: ws['D' + i]['v'],
            quantite: ws['I' + i]['v'],
            prix: ws['G' + i]['v'],
            pays: ws['J' + i]['v'],
            quota: ws['H' + i]['v'],
            brute: ws['E' + i]['v'],
            net: ws['F' + i]['v'],
          });

          z = z + 1;
        }
      }

      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.Article_DATA);
        this.spinner.hide();
      }, 800);

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
    };
  }


}
