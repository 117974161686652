<div class="bloc" [ngStyle]="{ direction: direction }">
  <div class="c1">
    <div class="Trac-2081"></div>
    <div style="text-align: center">
      <h2 class="titre-page">FDE</h2>
      <div class="about-border"></div>
    </div>
  </div>
<!-- <div class="row">
  <div class="col-lg-12">
    <mat-stepper>
      <mat-step [stepControl]="FormExp" label="EXP/IMP">
        <div class="steppclass">
          <ng-template matStepperIcon="edit">
            <mat-icon>check</mat-icon>
          </ng-template>
         
        </div>
        <div>
          <div class="div_back">
            <button class="buttonback" mat-button matStepperPrevious>
              Retour
            </button>
          </div>
          <div class="div_next">
            <button class="buttonnext" mat-button matStepperNext>
              Suivant
            </button>
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="FormFde" label="FDE">
        <div class="steppclass">
          <ng-template matStepperIcon="edit">
            <mat-icon>check</mat-icon>
          </ng-template>
    
          <div class="row">
            <div class="col-lg-5 bf1">
              <form [formGroup]="FormFde" autocomplete="off" novalidate form>
            

                <div>
                  <div style="text-align: center">
                    <label class="lab_titre">Domiciliation</label>
                  </div>

                  <div style="padding-left: 2%; padding-top: 2%">
                    <div>
                      <label class="titrechamp"> Date </label>
                    </div>

                    <mat-form-field>
                      <input
                        matInput
                        [matDatepicker]="picker2"
                        [min]="date_current"
                        placeholder="Date Domiciliation"
                        [formControl]="datedomiciliation"
                        (dateInput)="onchangedatedomiciliation($event.value)"
                        style="color: #1d3a66"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker2"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                  </div>

                  <div style="padding-left: 1%">
                    <div>
                      <label class="titrechamp"> Code D'agence </label>
                    </div>
                    <ng-select
                      formControlName="code_agence_dom"
                      [items]="BankList"
                      (change)="onchangedomiciationagece($event)"
                      bindLabel="nameOrg"
                      placeholder=""
                      [(ngModel)]="nom_agence_dom"
                    >
                    </ng-select>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-lg-5 bf1">
              <form [formGroup]="FormSign" autocomplete="off" novalidate form>
                <div style="text-align: center">
                  <label class="lab_titre">Declarant </label>
                </div>
                <div style="padding-top: 3%">
                  <div>
                    <label class="titrechamp"> Nom signature </label>

                    <div style="padding-top: 1%">
                      <input
                        required
                        type="text"
                        mdbInput
                        class="form-control mb-4"
                        formControlName="nomsignd"
                      />
                    </div>
                  </div>
                  <div>
                    <label class="titrechamp"> Date Signature </label>

                    <div style="padding-top: 1%">
                      <mat-form-field>
                        <input
                          placeholder="Date Signature"
                          matInput
                          [min]="date_current"
                          [matDatepicker]="picker7"
                          [formControl]="datesignd"
                          (dateInput)="onchangedatesignd($event.value)"
                          style="color: #1d3a66"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker7"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker7></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div>
          <div class="div_next">
            <button class="buttonnext" mat-button matStepperNext>
              Suivant
            </button>
          </div>
        </div>
      </mat-step>
      <mat-step [stepControl]="FormDiv" label="Divers">
        <div class="steppclass">
          <ng-template matStepperIcon="edit">
            <mat-icon>check</mat-icon>
          </ng-template>
          <form [formGroup]="FormDiv" autocomplete="off" novalidate form>
            <div style="padding: 2% 1% 1% 1%; display: flex">
              <div style="padding-top: 1%">
                <label class="lab_titre">Mode livraison </label>
              </div>
              <div style="padding: 0% 1% 1% 1%">
                <ng-select
                  style="width: 350px"
                  formControlName="modelivraison"
                  [items]="modelivraisonlist"
                  bindLabel="name"
                  placeholder=""
                  [(ngModel)]="modelivraison"
                >
                </ng-select>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-5 bf1">
                <div style="text-align: center">
                  <label class="lab_titre">Contrat Commercial</label>
                </div>
                <div style="padding-top: 3%">
                  <div>
                    <div>
                      <label class="titrechamp"> Code </label>
                    </div>
                    <ng-select
                      formControlName="codecontrat"
                      [items]="codecontratlist"
                      bindLabel="name"
                      placeholder=""
                      [(ngModel)]="codecontrat"
                    >
                    </ng-select>
                  </div>

                  <div>
                    <label class="titrechamp"> Date Contrat </label>

                    <div style="padding-top: 1%">
                      <mat-form-field style="width: 100%">
                        <input
                          placeholder="Date Contrat"
                          matInput
                          [min]="date_current"
                          [matDatepicker]="picker3"
                          [formControl]="datecontract"
                          (dateInput)="onchangedateconrat($event.value)"
                          style="color: #1d3a66"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker3"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-5 bf1">
                <div style="text-align: center">
                  <label class="lab_titre">Nature autorisation </label>
                </div>
                <div style="padding-top: 2%">
                  <div>
                    <div>
                      <label class="titrechamp"> Code </label>
                    </div>
                    <div style="padding-top: 1%">
                      <ng-select
                        formControlName="numnature"
                        [items]="naturelist"
                        bindLabel="name"
                        placeholder=""
                        [(ngModel)]="numnature"
                      >
                      </ng-select>
                    </div>
                  </div>

                  <div>
                    <label class="titrechamp"> Libelle </label>

                    <div style="padding-top: 1%">
                      <input
                        required
                        type="text"
                        mdbInput
                        class="form-control mb-4"
                        formControlName="libellenature"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div>
          <div class="div_back">
            <button class="buttonback" mat-button matStepperPrevious>
              Retour
            </button>
          </div>
          <div class="div_next">
            <button class="buttonnext" mat-button matStepperNext>
              Suivant
            </button>
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="FormArticle" label="Articles / Reserves">
        <ng-template matStepperIcon="edit">
          <mat-icon>check</mat-icon>
        </ng-template>
        <div class="steppclass">
          <div style="text-align: center; padding: 2% 35% 1% 40%">
            <div style="padding-top: 1%">
              <button
                (click)="open(content2)"
                type="button"
                class="btn button_file"
                style="display: flex"
              >
                <div>
                  <mat-icon class="iconadd">add</mat-icon>
                </div>
                <div style="padding-top: 3px">
                  <span class="article"> Nouveau Article </span>
                </div>
              </button>
            </div>
          </div>

          <div class="div_table">
            <table mat-table [dataSource]="dataSource">
             
              <ng-container matColumnDef="no">
                <th mat-header-cell *matHeaderCellDef class="headercell">
                  No.
                </th>
                <td mat-cell *matCellDef="let element" class="tdcell">
                  {{ element.numeroArticle }}
                </td>
              </ng-container>

              <ng-container matColumnDef="ndp">
                <th mat-header-cell *matHeaderCellDef class="headercell">
                  NDP
                </th>
                <td mat-cell *matCellDef="let element" class="tdcell">
                  {{ element.uniteMesure }}
                </td>
              </ng-container>

              <ng-container matColumnDef="des">
                <th mat-header-cell *matHeaderCellDef class="headercell">
                  Désignation
                </th>
                <td mat-cell *matCellDef="let element" class="tdcell">
                  {{ element.designation }}
                </td>
              </ng-container>

              <ng-container matColumnDef="pfn">
                <th mat-header-cell *matHeaderCellDef class="headercell">
                  PFN
                </th>
                <td mat-cell *matCellDef="let element" class="tdcell">
                  {{ element.prix }}
                </td>
              </ng-container>

              <ng-container matColumnDef="codeqcs">
                <th mat-header-cell *matHeaderCellDef class="headercell">
                  Code QCS
                </th>
                <td mat-cell *matCellDef="let element" class="tdcell">
                  {{ element.nomenclature }}
                </td>
              </ng-container>

              <ng-container matColumnDef="qcs">
                <th mat-header-cell *matHeaderCellDef class="headercell">
                  QCS
                </th>
                <td mat-cell *matCellDef="let element" class="tdcell">
                  {{ element.quantite }}
                </td>
              </ng-container>

              <ng-container matColumnDef="pays">
                <th mat-header-cell *matHeaderCellDef class="headercell">
                  Pays Origine
                </th>
                <td mat-cell *matCellDef="let element" class="tdcell">
                  {{ element.codePaysOrigine }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </div>
        <div>
          <div class="div_back">
            <button class="buttonback" mat-button matStepperPrevious>
              Retour
            </button>
          </div>
          <div class="div_next">
            <button class="buttonnext" mat-button matStepperNext>
              Suivant
            </button>
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="FormReg" label="Réglement">
        <div class="steppclass">
          <ng-template matStepperIcon="edit">
            <mat-icon>check</mat-icon>
          </ng-template>
          <form [formGroup]="FormReg" autocomplete="off" novalidate form>
            <div class="row">
              <div class="col-lg-5 bf1">
                <div style="text-align: center">
                  <label class="lab_titre">Reglement Financier</label>
                </div>
                <div style="padding-top: 3%">
                  <div>
                    <div>
                      <label class="titrechamp"> Mode Réglement </label>
                    </div>
                    <ng-select
                      formControlName="modereg"
                      [items]="modereglist"
                      bindLabel="name"
                      placeholder=""
                      [(ngModel)]="moderegg"
                    >
                    </ng-select>
                  </div>

                  <div>
                    <label class="titrechamp"> Delai </label>

                    <ng-select
                      formControlName="delaireg"
                      [items]="delaireglist"
                      bindLabel="name"
                      placeholder=""
                      [(ngModel)]="delaireg"
                    >
                    </ng-select>
                  </div>

                  <div>
                    <label class="titrechamp"> Devis reglement </label>

                    <ng-select
                      formControlName="devisreg"
                      [items]="devisreglist"
                      bindLabel="name"
                      placeholder=""
                      [(ngModel)]="devisreg"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="col-lg-5 bf1">
                <div style="text-align: center">
                  <label class="lab_titre">Montant en devis </label>
                </div>
                <div style="padding-top: 2%">
                  <div>
                    <div>
                      <label class="titrechamp"> Devise </label>
                    </div>

                    <ng-select
                      formControlName="devismonant"
                      [items]="devislistmontant"
                      bindLabel="name"
                      placeholder=""
                      [(ngModel)]="devismont"
                    >
                    </ng-select>
                  </div>

                  <div>
                    <label class="titrechamp"> PTFN </label>

                    <div style="padding-top: 1%">
                      <input
                        required
                        type="number"
                        mdbInput
                        class="form-control mb-4"
                        formControlName="pten"
                      />
                    </div>
                  </div>

                  <div>
                    <label class="titrechamp"> FOB </label>

                    <div style="padding-top: 1%">
                      <input
                        required
                        type="number"
                        mdbInput
                        class="form-control mb-4"
                        formControlName="fob"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-11 bf1">
                <div style="text-align: center">
                  <label class="lab_titre">Montants en Dinars </label>
                </div>

                <div class="row" style="padding-top: 2%">
                  <div class="col-lg-5">
                    <div>
                      <label class="titrechamp"> PTFN </label>
                    </div>
                    <div style="padding-top: 1%">
                      <input
                        required
                        type="number"
                        mdbInput
                        class="form-control mb-4"
                        formControlName="ptend"
                      />
                    </div>
                  </div>

                  <div class="col-lg-5">
                    <div>
                      <label class="titrechamp"> FOB </label>
                    </div>

                    <input
                      required
                      type="number"
                      mdbInput
                      class="form-control mb-4"
                      formControlName="fobd"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div>
          <div class="div_back">
            <button class="buttonback" mat-button matStepperPrevious>
              Retour
            </button>
          </div>
          <div class="div_next">
            <button class="buttonnext" mat-button matStepperNext>
              Suivant
            </button>
          </div>
        </div>
      </mat-step>

      <mat-step label="Pièces jointes">
        <div class="steppclass">
          <ng-template matStepperIcon="edit">
            <mat-icon>check</mat-icon>
          </ng-template>

          <div style="text-align: center; padding: 2% 35% 1% 40%">
            <div style="padding-top: 1%">
              <button
                (click)="open(content1)"
                type="button"
                class="btn button_file"
                style="display: flex"
              >
                <div>
                  <mat-icon class="iconadd">add</mat-icon>
                </div>
                <div style="padding-top: 3px">
                  <span class="piece"> Nouveau Piéce Jointe </span>
                </div>
              </button>
            </div>
          </div>
          <div class="row" style="margin: 1% 1% 1% 1%">
            <div class="div_table">
              <table mat-table [dataSource]="dataSourcePieces">
              
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    Name
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell">
                    {{ element.name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="file">
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    Fichier
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell">
                    {{ element.file.name }}
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumnsPieces"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsPieces"
                ></tr>
              </table>
            </div>
          </div>
        </div>
        <div>
          <div class="div_back">
            <button class="buttonback" mat-button matStepperPrevious>
              Retour
            </button>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
  <div class="col-lg-12">
    <div class="col-lg-12 actions">
      <div class="btnmobile" *ngIf="etat != 'Confirm'">
        <button
          type="button"
          [disabled]="
           
          "
          class="btn buttonsave"
          (click)="Submit(content4)"
        >
          <label class="labsave"> submit</label>
        </button>
      </div>
      <div class="btnmobile" *ngIf="etat != 'Confirm'">
        <button
          type="button"
         
          class="btn buttonsave"
          (click)="Save(content3)"
        >
          <label class="labsave">save </label>
        </button>
      </div>

      <div class="btnmobile" *ngIf="etat == 'Confirm'">
        <button
          type="button"
          [disabled]="
  
          "
          class="btn buttonsave"
          (click)="Submit(content4)"
        >
          <label class="labsave"> Submit</label>
        </button>
      </div>

      <div class="btnmobile">
        <input
          style="display: none"
          type="file"
          #fileInput
          (change)="onFileChange($event)"
          name="uploadFile"
          multiple="multiple"
          #hiddenfileinput
        />
        <button
          type="button"
          class="btn buttonupload"
          (click)="hiddenfileinput.click()"
        >
          <label class="labupload"> upload</label>
        </button>
      </div>
      <div class="btnmobile">
        <button (click)="Go_list()" type="button" class="btn buttonlist">
          <div style="padding-top: 3px">
            <label class="lablist"> list </label>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>

 -->

<div class="row" [ngStyle]="{ direction: direction }">
  <div class="row justify-content-center">
    <div class="col-lg-12 text-center p-0 mt-3 mb-2">
      <div class="px-0 pt-4 pb-0 mt-3 mb-3">
        <form id="form">
          <ul id="progressbar">
            <li
              [ngStyle]="{
                float: langue === 'AR' ? 'right' : 'left'
              }"
              (click)="changebloc(1)"
              [class]="activestep1"
              id="step1"
              style="cursor: pointer"
            >
              <strong> {{ languef.informationsgenerale }} </strong>
            </li>
            <li
              [ngStyle]="{
                float: langue === 'AR' ? 'right' : 'left'
              }"
              (click)="changebloc(2)"
              [class]="activestep2"
              id="step2"
              style="cursor: pointer"
            >
              <strong> FDE </strong>
            </li>
            <li
              [ngStyle]="{
                float: langue === 'AR' ? 'right' : 'left'
              }"
              (click)="changebloc(3)"
              [class]="activestep3"
              id="step3"
              style="cursor: pointer"
            >
              <strong> Articles</strong>
            </li>
            <li
              [ngStyle]="{
                float: langue === 'AR' ? 'right' : 'left'
              }"
              (click)="changebloc(4)"
              [class]="activestep4"
              id="step4"
              style="cursor: pointer"
            >
              <strong> Réglement</strong>
            </li>

            <li
              [ngStyle]="{
                float: langue === 'AR' ? 'right' : 'left'
              }"
              (click)="changebloc(5)"
              [class]="activestep5"
              id="step5"
              style="cursor: pointer"
            >
              <strong> Pièces jointes</strong>
            </li>
          </ul>
          <div class="progress">
            <div class="pbar"></div>
          </div>
          <br />
          <div class="bloc1">
            <fieldset [ngStyle]="{ display: contour === 1 ? 'block' : 'none' }">
              <form [formGroup]="FormExp" autocomplete="off" novalidate form>
                <div class="row">
                  <div class="col-lg-6 contenu">
                    <div class="componentWrapper_2 exporter-wrapper">
                      <div class="header">Informations Exportateur</div>
                      <div style="padding-top: 2%">
                        <div class="row">
                          <div class="col-lg-6">
                            <div>
                              <label class="titrechamp"> Nom prénom</label>
                            </div>
                            <div style="padding-top: 1%">
                              <input
                                readonly
                                required
                                type="text"
                                mdbInput
                                class="form-control mb-4"
                                formControlName="nomexportateur"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div>
                              <label class="titrechamp"> Raison sociale </label>
                            </div>
                            <div style="padding-top: 1%">
                              <input
                                readonly
                                type="text"
                                mdbInput
                                class="form-control mb-4"
                                formControlName="rs"
                              />
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <label class="titrechamp"
                              >Code Exportatuer (RNE)</label
                            >

                            <div style="padding-top: 2%">
                              <input
                                matInput
                                readonly
                                class="form-control"
                                type="text"
                                placeholder="{{ reponseRequired1 }}"
                                formControlName="codeExportateur"
                              />
                            </div>
                          </div>

                          <div class="col-lg-6 labeldetail">
                            <div>
                              <label class="titrechamp"> Rib </label>
                            </div>
                            <div style="padding-top: 2%">
                              <input
                                required
                                matInput
                                class="form-control"
                                type="text"
                                placeholder="{{ reponseRequired1 }}"
                                formControlName="ribClient"
                              />
                            </div>
                          </div>

                          <div class="col-lg-12">
                            <div>
                              <label class="titrechamp"> Adresse ligne </label>
                            </div>
                            <div style="padding-top: 1%">
                              <input
                                required
                                readonly
                                type="text"
                                mdbInput
                                class="form-control mb-12"
                                style="width: 100%"
                                formControlName="add1"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 contenu">
                    <div class="componentWrapper_2 importer-wrapper">
                      <div class="header">Informations Importateur</div>
                      <div style="padding-top: 2%">
                        <div class="row">
                          <div class="col-lg-6">
                            <div>
                              <label class="titrechamp"> Nom prénom</label>
                            </div>
                            <div style="padding-top: 1%">
                              <input
                                required
                                type="text"
                                mdbInput
                                class="form-control mb-4"
                                formControlName="nomimportateur"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div>
                              <label class="titrechamp"> Raison sociale </label>
                            </div>
                            <div style="padding-top: 1%">
                              <input
                                type="text"
                                mdbInput
                                class="form-control mb-4"
                                formControlName="rs1"
                              />
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <div>
                              <label class="titrechamp"> Adresse ligne </label>
                            </div>
                            <div style="padding-top: 1%">
                              <input
                                required
                                type="text"
                                mdbInput
                                class="form-control mb-12"
                                style="width: 100%"
                                formControlName="add2"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 contenu">
                    <div class="componentWrapper_2">
                      <div class="header">Pays</div>
                      <div style="padding-top: 2%">
                        <div class="row">
                          <div class="col-lg-3">
                            <div>
                              <label class="titrechamp"> Provenance </label>
                            </div>
                            <div style="padding-top: 1%">
                              <mat-select-country
                                placeHolder=""
                                [value]="Valuep"
                                (onCountrySelected)="onCountrySelectedP($event)"
                              >
                              </mat-select-country>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div>
                              <label class="titrechamp"> Achat </label>
                            </div>
                            <div style="padding-top: 1%">
                              <mat-select-country
                                placeHolder=""
                                [value]="Valuea"
                                style="color: #1d3a66"
                                (onCountrySelected)="onCountrySelectedA($event)"
                              >
                              </mat-select-country>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div>
                              <label class="titrechamp">
                                1ér destination
                              </label>
                            </div>
                            <div style="padding-top: 1%">
                              <mat-select-country
                                placeHolder=""
                                [value]="Valued1"
                                (onCountrySelected)="
                                  onCountrySelectedD1($event)
                                "
                              >
                              </mat-select-country>
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <div>
                              <label class="titrechamp">
                                Destination difinitive
                              </label>
                            </div>
                            <div style="padding-top: 1%">
                              <mat-select-country
                                placeHolder=""
                                [value]="Valuedf"
                                (onCountrySelected)="
                                  onCountrySelectedDF($event)
                                "
                              >
                              </mat-select-country>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div>
                <div
                  [ngStyle]="{
                    float: langue === 'AR' ? 'left' : 'right'
                  }"
                >
                  <input
                    (click)="changebloc(2)"
                    type="button"
                    name="next-step"
                    class="next-step"
                    value="{{ languef.next }}"
                  />
                </div>
              </div>
            </fieldset>
            <fieldset
              [ngStyle]="{
                display: contour === 2 ? 'block' : 'none'
              }"
            >
              <form [formGroup]="FormFde" autocomplete="off" novalidate form>
                <div class="row">
                  <div class="col-lg-6 contenu">
                    <div class="componentWrapper_2">
                      <div class="header">Domiciliation</div>
                      <div style="padding-top: 2%">
                        <div class="row">
                          <div class="row">
                            <div class="col-lg-6">
                              <div style="padding-left: 3%">
                                <div>
                                  <label class="titrechamp"> Date </label>
                                </div>
                                <mat-form-field>
                                  <input
                                    [disabled]="true"
                                    matInput
                                    [matDatepicker]="picker2"
                                    [min]="date_current"
                                    placeholder="Date Domiciliation"
                                    [formControl]="datedomiciliation"
                                    style="color: #1d3a66"
                                  />
                                  <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker2"
                                  ></mat-datepicker-toggle>
                                  <mat-datepicker
                                    #picker2
                                    [disabled]="true"
                                  ></mat-datepicker>
                                </mat-form-field>
                              </div>
                            </div>

                            <div class="col-lg-6">
                              <div>
                                <label class="titrechamp">
                                  Numéro domiciliation
                                </label>
                                <div style="padding-top: 2%">
                                  <div style="padding-top: 2%">
                                    <input
                                      readonly
                                      matInput
                                      class="form-control"
                                      type="number"
                                      placeholder=""
                                      formControlName="numeroDomiciliation"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-lg-4">
                              <div style="padding-left: 1%">
                                <div>
                                  <label class="titrechamp">
                                    Désignation
                                  </label>
                                </div>
                                <ng-select
                                  formControlName="nom_agence_dom"
                                  [items]="BankList"
                                  (change)="onchangedomiciationagece($event)"
                                  bindLabel="nameOrg"
                                  placeholder=""
                                  [(ngModel)]="nom_agence_dom"
                                >
                                </ng-select>
                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div style="padding-left: 1%">
                                <div>
                                  <label class="titrechamp">
                                    Agence
                                  </label>
                                </div>
                                <ng-select
                                    formControlName="nom_agence"
                                    [items]="AgenceList"
                                    (change)="onchangenomagece($event)"
                                    bindLabel="agence"
                                    bindValue="agence" 
                                    placeholder="Sélectionnez une agence"
                                    [(ngModel)]="nom_agence"
                                >
                                </ng-select>
                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div>
                                <label class="titrechamp"> Code banque</label>
                                <div style="padding-top: 2%">
                                  <input
                                    readonly
                                    matInput
                                    class="form-control"
                                    type="number"
                                    placeholder=""
                                    formControlName="code_agence_dom"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 contenu">
                    <div class="componentWrapper_2">
                      <div class="header">Declarant</div>
                      <div style="padding-top: 2%">
                        <div class="row">
                          <div style="padding-top: 3%">
                            <div>
                              <label class="titrechamp"> Nom signature </label>

                              <div style="padding-top: 1%">
                                <input
                                  required
                                  type="text"
                                  mdbInput
                                  class="form-control mb-4"
                                  formControlName="nomsignd"
                                />
                              </div>
                            </div>
                            <div>
                              <label class="titrechamp"> Date Signature </label>

                              <div style="padding-top: 1%">
                                <mat-form-field>
                                  <input
                                    placeholder="Date Signature"
                                    matInput
                                    [min]="date_current"
                                    [matDatepicker]="picker7"
                                    [formControl]="datesignd"
                                    (dateInput)="
                                      onchangedatesignd($event.value)
                                    "
                                    style="color: #1d3a66"
                                  />
                                  <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker7"
                                  ></mat-datepicker-toggle>
                                  <mat-datepicker #picker7></mat-datepicker>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style="padding: 2% 1% 1% 1%; display: flex">
                    <div style="padding-top: 1%">
                      <label class="lab_titre">Mode livraison </label>
                    </div>
                    <div style="padding: 0% 1% 1% 1%">
                      <ng-select
                        style="width: 350px"
                        formControlName="modelivraison"
                        [items]="modelivraisonlist"
                        bindLabel="name"
                        placeholder=""
                        [(ngModel)]="modelivraison"
                      >
                      </ng-select>
                    </div>
                  </div>

                  <div class="col-lg-6 contenu">
                    <div class="componentWrapper_2">
                      <div class="header">Contrat Commercial</div>
                      <div style="padding-top: 2%">
                        <div class="row">
                          <div style="padding-top: 3%">
                            <div>
                              <div>
                                <label class="titrechamp"> Code </label>
                              </div>
                              <ng-select
                                formControlName="codecontrat"
                                [items]="codecontratlist"
                                bindLabel="name"
                                placeholder=""
                                [(ngModel)]="codecontrat"
                              >
                              </ng-select>
                            </div>

                            <div>
                              <label class="titrechamp"> Date Contrat </label>

                              <div style="padding-top: 1%">
                                <mat-form-field style="width: 100%">
                                  <input
                                    placeholder="Date Contrat"
                                    matInput
                                    [min]="date_current"
                                    [matDatepicker]="picker3"
                                    [formControl]="datecontract"
                                    (dateInput)="
                                      onchangedateconrat($event.value)
                                    "
                                    style="color: #1d3a66"
                                  />
                                  <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker3"
                                  ></mat-datepicker-toggle>
                                  <mat-datepicker #picker3></mat-datepicker>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 contenu">
                    <div class="componentWrapper_2">
                      <div class="header">Nature autorisation</div>
                      <div style="padding-top: 2%">
                        <div class="row">
                          <div style="padding-top: 2%">
                            <div>
                              <div>
                                <label class="titrechamp"> Code </label>
                              </div>
                              <div style="padding-top: 1%">
                                <ng-select
                                  formControlName="numnature"
                                  [items]="naturelist"
                                  bindLabel="name"
                                  placeholder=""
                                  [(ngModel)]="numnature"
                                >
                                </ng-select>
                              </div>
                            </div>

                            <div>
                              <label class="titrechamp"> Libelle </label>

                              <div style="padding-top: 1%">
                                <input
                                  required
                                  type="text"
                                  mdbInput
                                  class="form-control mb-4"
                                  formControlName="libellenature"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div>
                <div
                  [ngStyle]="{
                    float: langue === 'AR' ? 'left' : 'right'
                  }"
                >
                  <input
                    (click)="changebloc(3)"
                    type="button"
                    name="next-step"
                    class="next-step"
                    value="{{ languef.next }}"
                  />
                </div>

                <div
                  [ngStyle]="{
                    float: langue === 'AR' ? 'right' : 'left'
                  }"
                >
                  <input
                    (click)="changebloc(1)"
                    type="button"
                    name="pre-step"
                    class="pre-step"
                    value="{{ languef.previous }}"
                  />
                </div>
              </div>
            </fieldset>
            <fieldset
              [ngStyle]="{
                display: contour === 3 ? 'block' : 'none'
              }"
            >
              <div class="row">
                <div class="col-lg-12">
                  <div class="componentWrapper">
                    <div class="header">Articles</div>

                    <div class="steppclass">
                      <div style="text-align: center; padding: 2% 35% 1% 40%">
                        <div style="padding-top: 1%">
                          <button
                            (click)="open(content2)"
                            type="button"
                            class="btn button_file"
                            style="display: flex"
                          >
                            <div style="padding-top: 3px">
                              <span class="article"> Nouveau Article </span>
                            </div>
                          </button>
                        </div>
                      </div>

                      <div class="div_table">
                        <table mat-table [dataSource]="dataSource">
                          <!--- Note that these columns can be defined in any order.
                            The actual rendered columns are set as a property on the row definition" -->

                          <!-- Position Column -->
                          <ng-container matColumnDef="no">
                            <th
                              mat-header-cell
                              *matHeaderCellDef
                              class="headercell"
                            >
                              No.
                            </th>
                            <td
                              mat-cell
                              *matCellDef="let element"
                              class="tdcell"
                            >
                              {{ element.numeroArticle }}
                            </td>
                          </ng-container>

                          <!-- Name Column -->
                          <ng-container matColumnDef="ngp">
                            <th
                              mat-header-cell
                              *matHeaderCellDef
                              class="headercell"
                            >
                              NGP
                            </th>
                            <td
                              mat-cell
                              *matCellDef="let element"
                              class="tdcell"
                            >
                              {{ element.nomenclature }}
                            </td>
                          </ng-container>

                          <!-- Désignation Column -->
                          <ng-container matColumnDef="des">
                            <th
                              mat-header-cell
                              *matHeaderCellDef
                              class="headercell"
                            >
                              Désignation
                            </th>
                            <td
                              mat-cell
                              *matCellDef="let element"
                              class="tdcell"
                            >
                              {{ element.designation }}
                            </td>
                          </ng-container>

                          <!-- PFN Column -->
                          <ng-container matColumnDef="pfn">
                            <th
                              mat-header-cell
                              *matHeaderCellDef
                              class="headercell"
                            >
                              PFN
                            </th>
                            <td
                              mat-cell
                              *matCellDef="let element"
                              class="tdcell"
                            >
                              {{ element.prix }}
                            </td>
                          </ng-container>

                          <!-- PFN Code QCS -->
                          <ng-container matColumnDef="codeqcs">
                            <th
                              mat-header-cell
                              *matHeaderCellDef
                              class="headercell"
                            >
                              Code QCS
                            </th>
                            <td
                              mat-cell
                              *matCellDef="let element"
                              class="tdcell"
                            >
                              {{ element.uniteMesure }}
                            </td>
                          </ng-container>

                          <!-- PFN QCS -->
                          <ng-container matColumnDef="qcs">
                            <th
                              mat-header-cell
                              *matHeaderCellDef
                              class="headercell"
                            >
                              QCS
                            </th>
                            <td
                              mat-cell
                              *matCellDef="let element"
                              class="tdcell"
                            >
                              {{ element.quantite }}
                            </td>
                          </ng-container>

                          <!-- PFN Pays Origine -->
                          <ng-container matColumnDef="pays">
                            <th
                              mat-header-cell
                              *matHeaderCellDef
                              class="headercell"
                            >
                              Pays Origine
                            </th>
                            <td
                              mat-cell
                              *matCellDef="let element"
                              class="tdcell"
                            >
                              {{ element.nomPaysOrigine }}
                            </td>
                          </ng-container>

                          <tr
                            mat-header-row
                            *matHeaderRowDef="displayedColumns"
                          ></tr>
                          <tr
                            mat-row
                            *matRowDef="let row; columns: displayedColumns"
                          ></tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  [ngStyle]="{
                    float: langue === 'AR' ? 'right' : 'left'
                  }"
                >
                  <input
                    (click)="changebloc(2)"
                    type="button"
                    name="pre-step"
                    class="pre-step"
                    value="{{ languef.previous }}"
                  />
                </div>

                <div
                  [ngStyle]="{
                    float: langue === 'AR' ? 'left' : 'right'
                  }"
                >
                  <input
                    (click)="changebloc(4)"
                    type="button"
                    name="next-step"
                    class="next-step"
                    value="{{ languef.next }}"
                  />
                </div>
              </div>
            </fieldset>
            <fieldset
              [ngStyle]="{
                display: contour === 4 ? 'block' : 'none'
              }"
            >
              <form [formGroup]="FormReg" autocomplete="off" novalidate form>
                <div class="row">
                  <div class="col-lg-6 contenu">
                    <div class="componentWrapper_2">
                      <div class="header">Reglement Financier</div>
                      <div style="padding-top: 2%">
                        <div>
                          <div>
                            <label class="titrechamp"> Mode Réglement </label>
                          </div>
                          <div style="padding-top: 2%">
                            <ng-select
                              formControlName="modereg"
                              [items]="modereglist"
                              bindLabel="name"
                              placeholder=""
                              [(ngModel)]="moderegg"
                            >
                            </ng-select>
                          </div>
                        </div>

                        <div style="padding-top: 2%">
                          <label class="titrechamp"> Delai </label>
                          <div style="padding-top: 2%">
                            <ng-select
                              formControlName="delaireg"
                              [items]="delaireglist"
                              bindLabel="name"
                              placeholder=""
                              [(ngModel)]="delaireg"
                            >
                            </ng-select>
                          </div>
                        </div>

                        <div style="padding-top: 2%">
                          <label class="titrechamp"> Devis reglement </label>
                          <div style="padding-top: 2%">
                            <ng-select
                              formControlName="devisreg"
                              [items]="devisreglist"
                              bindLabel="name"
                              placeholder=""
                              [(ngModel)]="devisreg"
                            >
                            </ng-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 contenu">
                    <div class="componentWrapper_2">
                      <div class="header">Montant en devis</div>
                      <div style="padding-top: 2%">
                        <div>
                          <div>
                            <label class="titrechamp"> Devise </label>
                          </div>
                          <div style="padding-top: 2%">
                            <ng-select
                              formControlName="devismonant"
                              [items]="devislistmontant"
                              bindLabel="name"
                              placeholder=""
                              [(ngModel)]="devismont"
                            >
                            </ng-select>
                          </div>
                        </div>

                        <div style="padding-top: 2%">
                          <label class="titrechamp"> PTFN </label>

                          <div style="padding-top: 2%">
                            <input
                              required
                              type="number"
                              mdbInput
                              class="form-control mb-4"
                              formControlName="pten"
                            />
                          </div>
                        </div>

                        <div style="padding-top: 2%">
                          <label class="titrechamp"> FOB </label>

                          <div style="padding-top: 2%">
                            <input
                              required
                              type="number"
                              mdbInput
                              class="form-control mb-4"
                              formControlName="fob"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 contenu">
                    <div class="componentWrapper_2">
                      <div class="header">Montants en Dinars</div>
                      <div class="row" style="padding-top: 2%">
                        <div class="col-lg-6">
                          <div>
                            <label class="titrechamp"> PTFN </label>
                          </div>
                          <div style="padding-top: 1%">
                            <input
                              required
                              type="number"
                              mdbInput
                              class="form-control mb-4"
                              formControlName="ptend"
                            />
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div>
                            <label class="titrechamp"> FOB </label>
                          </div>

                          <input
                            required
                            type="number"
                            mdbInput
                            class="form-control mb-4"
                            formControlName="fobd"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div>
                <div
                  [ngStyle]="{
                    float: langue === 'AR' ? 'right' : 'left'
                  }"
                >
                  <input
                    (click)="changebloc(4)"
                    type="button"
                    name="pre-step"
                    class="pre-step"
                    value="{{ languef.previous }}"
                  />
                </div>

                <div
                  [ngStyle]="{
                    float: langue === 'AR' ? 'left' : 'right'
                  }"
                >
                  <input
                    (click)="changebloc(5)"
                    type="button"
                    name="next-step"
                    class="next-step"
                    value="{{ languef.next }}"
                  />
                </div>
              </div>
            </fieldset>
            <fieldset
              [ngStyle]="{
                display: contour === 5 ? 'block' : 'none'
              }"
            >
              <div class="row">
                <div class="col-lg-12">
                  <div class="componentWrapper">
                    <div class="header">Pièces jointes</div>

                    <div style="text-align: center; padding: 2% 35% 1% 40%">
                      <div style="padding-top: 1%">
                        <button
                          (click)="open(content1)"
                          type="button"
                          class="btn button_file"
                          style="display: flex"
                        >
                          <div>
                            <mat-icon class="iconadd">add</mat-icon>
                          </div>
                          <div style="padding-top: 3px">
                            <span class="piece"> Nouveau </span>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  [ngStyle]="{
                    float: langue === 'AR' ? 'right' : 'left'
                  }"
                >
                  <input
                    (click)="changebloc(4)"
                    type="button"
                    name="pre-step"
                    class="pre-step"
                    value="{{ languef.previous }}"
                  />
                </div>
              </div>
            </fieldset>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="col-lg-12">
    <div class="col-lg-12 actions">
      <div class="btnmobile">
        <button type="button" class="btn buttonsave" (click)="Save(content3)">
          <label class="labsave"> {{ languef.save }}</label>
        </button>
      </div>
      <div class="btnmobile">
        <input
          style="display: none"
          type="file"
          #fileInput
          (change)="onFileChange($event)"
          name="uploadFile"
          multiple="multiple"
          #hiddenfileinput
        />
        <button
          type="button"
          class="btn buttonupload"
          (click)="hiddenfileinput.click()"
        >
          <label class="labupload"> upload</label>
        </button>
      </div>

      <div class="btnmobile">
        <button (click)="Go_list()" type="button" class="btn buttonlist">
          <div style="padding-top: 3px">
            <label class="lablist"> {{ languef.list }} </label>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="titre_modal" id="modal-basic-title">Nouveau Fichier</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <div class="bodymodal_file">
      <form [formGroup]="FormPieces" autocomplete="off" novalidate form>
        <div class="row">
          <div class="col-lg-12">
            <div>
              <label class="titrechamp"> Nom Piéce jointe </label>
            </div>
            <div style="padding-top: 1%">
              <input
                required
                type="text"
                mdbInput
                class="form-control mb-4"
                formControlName="name"
              />
            </div>
          </div>

          <div class="col-lg-12">
            <div>
              <label class="titrechamp"> Piéce jointe </label>
            </div>
            <div style="padding-top: 1%">
              <input
                formControlName="file"
                name="image2"
                (change)="onChangefile($event)"
                type="file"
                class="form-control-file"
                id="exampleFormControlFile1"
                style="font-size: 15px; padding-left: 2%"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="text-center" style="padding-bottom: 2%">
    <button
      [disabled]="FormPieces.invalid"
      type="button"
      class="btn button_file_save"
      (click)="file_add()"
    >
      <span class="save"> Ajouter</span>
    </button>
  </div>
</ng-template>

<ng-template #content2 let-modal>
  <div class="modal-header">
    <div style="text-align: center">
      <h4 class="titre_modal" id="modal-basic-title">Article</h4>
    </div>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <div class="bodymodal_file">
      <form [formGroup]="FormArticle" autocomplete="off" novalidate form>
        <div class="row">
          <div style="padding-top: 2%" class="row">
            <div class="col-lg-6">
              <div>
                <label class="titrechamp"> NGP </label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="text"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="ngp"
                />
              </div>
            </div>
            <!-- [(ngModel)]="ngp" -->
            <!--<div>
              <div>
                <label class="titrechamp"> NGP </label>
              </div>
              <ng-select
                formControlName="ngp"
                [items]="ngplist"
                bindLabel="name"
                placeholder=""
                
              >
              </ng-select>
            </div>-->
            <div class="col-lg-6">
              <div>
                <label class="titrechamp"> Libelle </label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="text"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="libelle"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div>
                <label class="titrechamp"> Quantité </label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="number"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="quantite"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div>
                <label class="titrechamp"> Code Qcs </label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="text"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="codeqcs"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div>
                <label class="titrechamp"> PFN </label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="text"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="pfn"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div>
                <label class="titrechamp"> Pays </label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="text"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="pays"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="text-center" style="padding-bottom: 2%">
    <button
      [disabled]="FormArticle.invalid"
      type="button"
      class="btn button_file_save"
      (click)="article_add()"
    >
      <span class="save"> Ajouter</span>
    </button>
  </div>
</ng-template>

<ng-template #content3>
  <div class="modal-body rounded-8">
    <div style="text-align: center">
      <div>
        <span
          class="material-icons green-color"
          style="
            font-size: 100px;
            color: #41d96f;
            text-align: center;
            font-weight: 800;
          "
          >check
        </span>
      </div>
      <div>
        <label
          style="
            font-family: Montserrat;
            font-size: 26px;
            padding-top: 2%;
            font-weight: bold;
            color: #073866;
          "
        >
          MERCI</label
        >
      </div>
      <div>
        <label
          style="
            width: 310px;
            font-size: 16px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          Votre Document Titre De Commerce a été enregistré</label
        >
      </div>
    </div>
  </div>
</ng-template>
<ng-template #content4>
  <div class="modal-body rounded-8">
    <div style="text-align: center">
      <div>
        <span
          class="material-icons green-color"
          style="
            font-size: 100px;
            color: #41d96f;
            text-align: center;
            font-weight: 800;
          "
          >check_circle
        </span>
      </div>
      <div>
        <label
          style="
            font-family: Montserrat;
            font-size: 26px;
            padding-top: 2%;
            font-weight: bold;
            color: #073866;
          "
        >
          titremodal</label
        >
      </div>
      <div>
        <label
          style="
            width: 310px;
            font-size: 16px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          descriptionsubmitcustoms</label
        >
      </div>
      <div class="text-center" style="padding-bottom: 2%; padding-top: 6%">
        <button type="button" class="btn button2" (click)="Go_list()">
          <span class="Save"> languea</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
